import { useNavigate } from 'react-router-dom';
import {
    useDeleteNewsAllMutation,
    useDeleteNewsMutation,
    useGetNewsListQuery,
} from '../../app/api/newsApiSlice';

const NewsPanel = (props) => {
    const { accountId } = props;
    const navigate = useNavigate();

    const { data: news } = useGetNewsListQuery({ accountId });
    const [deleteNews] = useDeleteNewsMutation();
    const [deleteNewsAll] = useDeleteNewsAllMutation();
    console.log('news', news);

    const setIsReadNews = (taskId) => {
        deleteNews(taskId);
    };

    const openTask = (taskId) => {
        navigate(`/account/${accountId}/tasks/${taskId}`);
    };

    return (
        <div className='card cx-card shadow-sm'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-8'>
                        <h5 className='card-title'>Лента новостей</h5>
                    </div>
                    <div className='col-md-4 text-right'>
                        <button
                            className='btn btn-primary'
                            onClick={deleteNewsAll}
                        >
                            Прочитать все
                        </button>
                    </div>
                </div>

                <ul className='list-group'>
                    {news?.result?.map((item) => (
                        <li
                            className='list-group-item position-relative'
                            key={item.id}
                        >
                            <span
                                className='position-absolute cursor-pointer'
                                style={{ top: '5px', right: '10px' }}
                                onClick={() => setIsReadNews(item.taskId)}
                            >
                                <i className='fa-solid fa-xmark text-muted' />
                            </span>
                            <div
                                className='fw-bold text-decoration-underline cursor-pointer'
                                onClick={() => openTask(item.taskId)}
                            >
                                #{item.taskId} {item.subject}
                            </div>
                            {item.description}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default NewsPanel;
